import React from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import {BrowserRouter as Router, Switch, Route, Link as RouterLink, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import {withRouter} from "react-router";

import Home from "./Home";
import Projects from "./Projects";
import Projectpartners from "./Projectpartners";
import Suppliers from "./Suppliers";
import Contact from "./Contact";
import Konfigurator from "./Konfigurator";

import CssBaseline from '@material-ui/core/CssBaseline';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Divider from '@material-ui/core/Divider';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import FlagIcon from '@material-ui/icons/Flag';

import MenuIcon from '@material-ui/icons/Menu';

import Carousel from 'react-material-ui-carousel';

import 'typeface-raleway';

import ZentimeterSVG from "./zentimeter.svg";
import ZentimeterOnRightSVG from "./zentimeter_onright.svg";
import LogoSVG from "./logo.svg";
import RobertBorowitzJPG from "./robert-borowitz.jpeg";
import SlidePicSRC from "./slidePic.jpg";

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Raleway, sans-serif',
  },
  palette: {
    primary: {
      main: '#0d61ae',
      light: '#578ee0',
      dark: '#00387e'
    },
    secondary: {
      main: '#546e7a',
      light: '#819ca9',
      dark: '#29434e'
    }
    /* metallic darker
    {
      main: '#455a64',
      light: '#718792',
      dark: '#1c313a'
    }
    */
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Raleway, sans-serif',
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  appBar: {
    display:'flex',
    /*flexWrap:'wrap',
    '& div' : {
      flexGrow: 1
    },
    '& .MuiTabs-root' : {
      flexGrow: 1
    },*/
  },
  toolbar: {
    flexGrow:1
  },
  tabs: {
    display:'flex',
    '& div.MuiTabs-flexContainer': {
      justifyContent:"center"
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.secondary.light,
      height:"4px"
    }
  },
  tab: {
    color: "white",
    '&:hover': {
      color: theme.palette.secondary.dark,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.secondary.light,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.secondary.light,
    }
  },
  /*tabs: {
    '& button:hover': {
      color: theme.palette.secondary,
      opacity: 1,
    },
    '& button.selected': {
      color: theme.palette.secondary.dark,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& button:focus': {
      color: theme.palette.secondary.main,
    },
  },*/
  layoutContainer: {
    marginTop: theme.spacing(2)
  },
  main: {

  },
  massstab: {
    height: "1.6cm",
    width: "100%",
    backgroundImage: `url(${ZentimeterSVG})`,
    backgroundSize: "2cm 1.6cm",
    backgroundRepeat: "repeat-x",
    transform: "rotate(180deg)",
  },
  massstabOnRight: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${ZentimeterOnRightSVG})`,
      backgroundSize: "1.6cm 2cm",
      backgroundRepeat: "repeat-y",
      opacity: "1",
      backgroundPosition:"left",
      paddingLeft:"1.6cm",
    },
  },
  banner: {
    display:"flex",
    justifyContent:"center",
    marginBottom:theme.spacing(4),
    '& img': {
      width:"60%",
      [theme.breakpoints.down('md')]: {
        width:"100%!important"
      },
    }
  },
  footer: {
    backgroundColor:theme.palette.secondary.main,
    color: "white",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    minHeight: "60vh"
  },
  footerLink: {
    color: "white",
    fontWeight: "bold"
  },
  service: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  supplier: {
    minHeight:"200px",
  },
  projectpartner: {
    minHeight:"200px",
  },
  meinePerson: {
    width:"100%"
  },
  meinePersonMedia: {
    height:"250px"
  },
  impressum: {
    textAlign:"justify",
    backgroundColor:"inherit",
    color:"inherit"
  },
  impressumSummary: {
    padding: 0
  },
  impressumSummaryContent: {
    display: "inline"
  },
  impressumDetails: {
    display: "inline"
  },
  datenschutz: {
    textAlign:"justify",
    backgroundColor:"inherit",
    color:"inherit"
  },
  datenschutzSummary: {
    padding: 0
  },
  datenschutzSummaryContent: {
    display: "inline"
  },
  datenschutzDetails: {
    display: "inline"
  }
}));

function MetaTags({title, path, description}){
  return(
      <Helmet
        titleTemplate={"borowitz FTT projekte GmbH - %s"}
        detaulTitle={"borowitz FTT projekte - Beratung, Aufmaß, Vertrieb und Baugutachten"}
      >
        <title>{title}</title>
        <meta
            name="description"
            content={description}
        />
        <meta property="og:description" content={description}/>
        <link rel="canonical" href={"https://bo-ftt-pro.de/"+path} />
      </Helmet>
  )
}

function App() {

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTopWithRouter />
        <Layout>
          <Switch>
            <Route path="/projekte">
              <Projects header={<Header />}/>
            </Route>
            <Route path="/lieferanten">
              <Suppliers header={<Header />}/>
            </Route>
            <Route path="/projektpartner">
              <Projectpartners header={<Header />}/>
            </Route>
            <Route path="/konfigurator">
              <Konfigurator header={<Header />}/>
            </Route>
            <Route path="/kontakt">
              <Contact header={<Header />}/>
            </Route>
            <Route path="/">
              <Home header={<Header />} />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

/*
  ToDo: Change to functional component
 */
class ScrollToTop extends React.Component{
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      console.log("scrolled");
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <React.Fragment />
  }
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

function Layout({children}) {
  const classes = useStyles();

  let loc = useLocation();
  const [value, setValue] = React.useState(loc.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.appBar}>
        {/*<Toolbar className={classes.toolbar}>
          <IconButton start="edge" color="inherit">
            <MenuIcon/>
          </IconButton>
          <Button variant="contained" color="secondary" href="mailto:borowitz@bo-ftt-pro.de">Kontaktieren Sie mich</Button>
        </Toolbar>*/}
        <Tabs aria-label="" value={value} className={`${classes.tabs} ${classes.indicator}`} onChange={handleChange} variant="scrollable" orientation="horizontal">
          {/*<Tab component={NavLink} label="Beratung & Aufmaß" className={classes.tab} to="/#beratungaufmass" href="/#beratungaufmass" />
          <Tab component={NavLink}  label="Baugutachten" className={classes.tab} to="/#baugutachten" />
          <Tab component={NavLink}  label="Projekte" className={classes.tab} to="/projekte" />
          <Tab component={NavLink}  label="Über mich" className={classes.tab} to="/#aboutme" />*/}
          <Tab component={RouterLink}  label="Beratung, Aufmaß & Vertrieb" className={classes.tab} to="/" value={"/"} />
          <Tab component={RouterLink}  label="Projekte" className={classes.tab} to="/projekte" value={"/projekte"} />
          <Tab component={RouterLink}  label="Projektpartner" className={classes.tab} to="/projektpartner" value={"/projektpartner"} />
          <Tab component={RouterLink}  label="Lieferanten" className={classes.tab} to="/lieferanten"  value={"/lieferanten"} />
          <Tab component={RouterLink}  label="Konfigurator" className={classes.tab} to="/konfigurator"  value={"/konfigurator"} />
          <Tab component={RouterLink}  label="Kontakt" className={classes.tab} to="/kontakt"  value={"/kontakt"} />
        </Tabs>
      </AppBar>
      <Container className={classes.layoutContainer}>
        <main className={classes.main}>
          {children}
        </main>
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Typography variant="body1">&copy; 2020 Robert Borowitz für bo-ftt-pro.de</Typography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" component="h5">Angaben gemäß § 5 TMG</Typography>
              <Typography variant="body2" component="p">
                borowitz FTT projekte GmbH<br/>
                Dorfstraße 5<br/>
                25560 Agethorst<br/>
                <br/>
                Geschäftsführer: Robert Borowitz
              </Typography><br/>
              <Typography variant="subtitle1" component="h5">Kontakt</Typography>
              <Typography variant="body2" component="p">
                Telefon: <Link color="textPrimary" href="tel:+4915229469447">+49 (0) 152 29469447</Link><br/>
                E-Mail: <Link color="textPrimary" href="mailto:borowitz@bo-ftt-pro.de">borowitz@bo-ftt-pro.de</Link>
              </Typography><br/>
              <Typography variant="subtitle1" component="h5">Umsatzsteuer-ID</Typography>
              <Typography variant="body2" component="p">
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br/>
                DE334448344
              </Typography><br/>
              <Typography variant="subtitle1" component="h5">Handelsregistereintrag</Typography>
              <Typography variant="body2" component="p">
                Amtsgericht Pinneberg, HRB 15218 PI
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem><Link className={classes.footerLink} to="/" component={RouterLink}>Beratung, Aufmaß & Vertrieb</Link></ListItem>
                <ListItem><Link className={classes.footerLink} to="/projekte" component={RouterLink}>Projekte</Link></ListItem>
                <ListItem><Link className={classes.footerLink} to="/projektpartner" component={RouterLink}>Projektpartner</Link></ListItem>
                <ListItem><Link className={classes.footerLink} to="/lieferanten" component={RouterLink}>Lieferanten</Link></ListItem>
                <ListItem><Link className={classes.footerLink} to="/konfigurator" component={RouterLink}>Konfigurator</Link></ListItem>
                <ListItem><Link className={classes.footerLink} to="/kontakt" component={RouterLink}>Kontakt</Link></ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </React.Fragment>
  )
}

function Header(){
  const classes = useStyles();
  return (
    <React.Fragment><Grid item xs="12" className={classes.banner}>
      <img src={LogoSVG} />
    </Grid>
    <Grid item xs="12">
      <Divider variant="fullWidth" component="hr" />
      <Typography variant="h2" component="h2" color="primary">borowitz <strong>FTT</strong> projekte GmbH</Typography>
      <Divider variant="fullWidth" component="hr" />
    </Grid></React.Fragment>
  );
}

function getStepContentService1(step) {
  switch (step) {
    case 0:
      return `TODO: Hier kommen noch Details!`;
    case 1:
      return 'TODO: Hier kommen noch Details!';
    case 2:
      return `TODO: Hier kommen noch Details!`;
    default:
      return 'Unknown step';
  }
}

function getStepsService1() {
  return ['Angebot anfordern und Beratung erhalten', 'Aufmaßtermin vereinbaren', 'Planung für Ihres individuellen Projekts'];
}


function Service1Stepper(){
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getStepsService1();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div>
      <Stepper activeStep={activeStep} orientation="vertical">
       {steps.map((label, index) => (
         <Step key={label}>
           <StepLabel>{label}</StepLabel>
           <StepContent>
             <Typography>{getStepContentService1(index)}</Typography>
             <div className={classes.actionsContainer}>
               <div>
                 <Button
                   disabled={activeStep === 0}
                   onClick={handleBack}
                   className={classes.button}
                 >
                   Zurück
                 </Button>
                 <Button
                   variant="contained"
                   color="primary"
                   onClick={handleNext}
                   className={classes.button}
                 >
                   {activeStep === steps.length - 1 ? 'Weiter' : 'Weiter'}
                 </Button>
               </div>
             </div>
           </StepContent>
         </Step>
       ))}
     </Stepper>
     {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>In diesen drei Schritten führen wir zusammen Ihr Projekt zum Erfolg! Erzählen Sie mir von Ihrem Projekt und erhalten Sie ein Angebot.</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Abbrechen
          </Button>
          <Button href="mailto:borowitz@bo-ftt-pro.de" className={classes.button} variant="contained" color="primary">
            Angebot anfordern
          </Button>
        </Paper>
      )}
    </div>
  )
}

export {App, theme, useStyles, MetaTags};
export default App;
